@import './general'

.tour-backdrop
  background-color: $color-dark-grey
  opacity: 0.6
  width: 100%
  height: 100%
  position: absolute
  z-index: 100
  display: block
  left: 0
  top: 0
  cursor: pointer

.item-backdrop
  content: ''
  background-color: $color-dark-grey
  opacity: 0.6
  width: 100%
  height: 100%
  position: fixed
  display: block
  left: 0
  top: 0
  z-index: 156
  

.selected-tour-item
  z-index: 180
  position: static !important
  background-color: $color-azure
  border-radius: 10px

  &.white-background
    background-color: $color-white
  
.safari-item
  position: inherit !important

.hopscotch-bubble.tour-fleetflex
  border: 0px  !important
  border-radius: 10px
  background-color: $color-azure
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5)
  color: #ffffff

  .hopscotch-bubble-container
    padding: 0 !important
    border-radius: 10px
    background-color: $color-azure

    .hopscotch-bubble-number
      display: none !important 
    
    .hopscotch-bubble-content
      color: #FFFFFF
      padding: 15px
      margin: 0 auto

      .hopscotch-content
        margin: 0
        padding: 0
        font-family: Roboto
        font-size: 14px
        font-weight: 500
        font-style: normal
        font-stretch: normal
        line-height: 1.5
        letter-spacing: normal
        color: $color-white

    .hopscotch-actions
      margin: 0 auto
      text-align: center

      button
        margin: 0 auto
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        height: 40px
        text-transform: uppercase
        background-color: transparent !important
        background-image: none !important
        border: 0 !important
        box-shadow: none !important
        cursor: pointer
        border-top: 1px solid $color-white !important

        &:hover
          background-image: none !important
          background-color: transparent !important

  .hopscotch-bubble-arrow-container
    &.up .hopscotch-bubble-arrow-border
      border-bottom-color: transparent !important
    &.right .hopscotch-bubble-arrow-border
      border-left-color: transparent !important
    &.left .hopscotch-bubble-arrow-border
      border-right-color: transparent !important

    &.up .hopscotch-bubble-arrow
      border-bottom: 9px solid $color-azure !important
      border-left: 20px solid transparent !important
      border-right: 20px solid transparent !important
      position: relative !important
      top: -4px !important
      
    &.right .hopscotch-bubble-arrow
      border-left: 12px solid $color-azure !important
      border-top: 20px solid transparent !important
      border-bottom: 20px solid transparent !important
      left: -8px
