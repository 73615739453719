@import fleetflex-functions

$icomoon-font-path: "../fonts" !default

//@font-face
  font-family: 'HelveticaNeue'
  src: url("#{$icomoon-font-path}/helvetica-nue/HelveticaNeueLight.ttf") format('truetype'), url("#{$icomoon-font-path}/helvetica-nue/HelveticaNeueLightItalic.ttf") format('truetype')
  font-weight: normal
  font-style: normal

@include font-face("HelveticaNeue", "#{$icomoon-font-path}/helvetica-nue/HelveticaNeueLight", 500, normal, ttf)