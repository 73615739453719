@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600)
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900')

$font-regular: 'Roboto', 'Open Sans', sans-serif
$base-font-size: 15

@function em($px, $base: $base-font-size)
  @return ($px / $base) * 1em

$color-solid-black: #000000
$color-white: #ffffff
$color-white-two: #efefef
$color-white-three: #fafafa
$color-white-four: #dcdcdc
$color-black: #202020
$color-dark-navy: #000a39
$color-blue: #184de9
$color-neon-blue: #00d5ff
$color-purplish-grey: #7b7a7d
$color-warm-grey: #979797
$color-light-grey: #ebebeb
$color-light-blue-grey: #d6e1f1
$color-apple-green: #7ed321
$color-cherry-red: #ff001f
$color-pinkish-grey: #c2c2c2
$color-tangerine: #f6a623
$color-warning: #f03a47
$color-azure: #03a9f4
$color-turquoise-blue: #00b8d4
$color-dark-grey: #4a4a4a
$color-blue-grey: #455a64
$color-light-blue: #00a9f4
$color-light-grey: #757575
$color-image-placeholder: #eeeeee
$color-red-alert: #d32f2f
$color-green-ok : #00c853
$color-green-ok-light : #81c784
$color-divisions : #e5e5e5
$color-divisions-light : #ededed
$color-table-row-divider: #e5e5e5
$color-mid-green: #4caf50
$color-pending-gray: #4f656f
$color-pending-gray-dark: #2e3b41
$color-mustace: #f9a21f
$color-brownie: #8b572a
$color-warning-yellow: #ffc107
$color-warning-yellow-dark: #FF9500
$color-slate: #385c6e

$color-status-green: #59B724
$color-status-yellow: #FEC135
$color-status-red: #D43C30

$color-dark-blue: #01579b
$color-dark-red: #d50000

$color-light-grey-panel: #e4e4e4
$color-black-87: rgb(0, 0, 0)
$color-black-54: rgba(0, 0, 0, 0.54)

$color-warning-soft: #f5a623

$color-card-divider: #d8d8d8
$color-card-avatar-background: #ededed
$color-special-white: rgba(255, 255, 255, 0.87)
$color-white-50: rgba(255, 255, 255, 0.5)

$color-fleetflex: #03A9F4
$color-text-placeHolder: #868686

$color-pin-status-complete: #50B600
$color-pin-status-complete-dark: #41870A

$color-pin-status-outside: #D32F2F
$color-pin-status-outside-dark: #8D0909

$box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2)

*
  outline: none !important

html
  height: 100%
  position: relative

body
  background: $color-white-three center center
  background-size: cover
  color: $color-black
  font-family: 'Roboto', sans-serif
  font-size: $base-font-size + px
  overflow-x: hidden
  overflow-y: auto
  height: 100vh
  max-height: 100%

.height-vh
  min-height: 100vh

h1,h2,h3,h4,h5,h6,
p,
span,
label,
a
  font-family: 'Roboto', sans-serif
  font-stretch: normal
  line-height: normal
  letter-spacing: normal

h1,h2,h3,h4,h5,h6
  color: $color-dark-navy

h1
  font-size: em(22)

h2
  font-size: em(19)

h3
  font-size: em(16)

p
  color: $color-purplish-grey
  font-size: em(15)
  line-height: 1.33

a
  color: $color-turquoise-blue
  text-decoration: none !important
  &:focus,
  &:active
    outline: none

  &:hover
    color: $color-neon-blue
    text-decoration: underline

//White text class
.white-text
  color: $color-white

// Margin and padding
$size-xs: 5px
$size-sm: 10px
$size-md: 15px
$size-xmd: 30px
$size-xlg: 40px
$size-lg: 50px
$size-xl: 80px
$size-xxl: 100px
$size-base: 15px
$size-xxm: 45px

// Screen Size
$screen-xlg:  1600px
$screen-xlg-min: $screen-xlg
// fleetflex font prefix
.ff
  display: inline-block
  color: $color-dark-navy
  font: normal normal normal 14px/1 fleetflex
  font-size: inherit
  text-rendering: auto
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

::ng-deep .modal-backdrop
  z-index: 999

.avatar
  vertical-align: middle
  width: 35px
  height: 35px
  border-radius: 50%
  &.avatar-s
    width: 20px
    height: 20px
  &.avatar-m
    width: 35px
    height: 35px
  &.avatar-xl
    width: 80px
    height: 80px
  &.avatar-outline
    border: black 2px solid
    text-align: center !important
    font-weight: bold
    color: black
    line-height: 35px
.customMarkerDiretions
  position: absolute
  cursor: pointer
  background: $color-white
  width: 26px
  height: 26px
  border-radius: 50%
  padding: 0
  .info
    position: absolute
    display: grid
    height: 26px
    opacity: 0.89
    border-radius: 2px
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2)
    background: $color-white
    margin-left: 30px
    .info-text
      font-size: 12px
      font-weight: 500
      margin: auto 15px auto 15px
      white-space: nowrap
  .container-i
    position: absolute
    background: $color-pending-gray
    width: 20px
    height: 20px
    margin: 3px
    border-radius: 50%
    i
      position: absolute
      color: $color-white
      font-size: 16px
      margin: 2px
.info-tootlip
  position: absolute
  display: flex
  align-items: flex-start
  flex-wrap: wrap
  background-color: $color-white
  min-height: 30px
  padding: 5px
  border-radius: 2px
  white-space: pre
  font-weight: normal
  font-size: 12px
  left: 34px
  top: 68px
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12)
  z-index: 100
  transition: all 300ms ease
  transform: rotate(45deg) !important

  &:after,
  &:before
    right: 100%
    top: 50%
    border: solid transparent
    content: " "
    height: 0
    width: 0
    position: absolute
    pointer-events: none

  &:after
    border-right-color: $color-white
    border-width: 8px
    margin-top: 5px

  &:before
    border-color: rgba(18, 21, 23, 0)
    border-right-color: rgba(0, 0, 0, 0.2)
    border-width: 9px
    margin-top: 4px

  p
    width: 100%
    display: flex
    align-items: center
    margin-bottom: 5px
    b
      font-weight: bold
      color: black
    &:last-child
      margin-bottom: 0
#loading-wrapper
    background: none
    position: fixed
    height: 100%
    left: 0
    opacity: 0.6
    top: 0
    width: 100%
    z-index: 90

@media (max-width: 768px)
  .hidden-small-device
    display: none !important
