/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat
@import 'assets/css/bootstrap-sass/variables'
@import '../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap'
@import '../node_modules/font-awesome/css/font-awesome.min.css'
@import "~bootstrap/dist/css/bootstrap.css"
@import 'assets/css/main'
.custom-dialog-container
  .mat-dialog-container
    padding: 0
    display: table

.custom-dialog-container-modal
  .mat-dialog-container
    padding: 0

.tippy-box
  background: white !important
  box-shadow: #7b7a7d 1px 1px 10px
  border-radius: 10px

.tippy-arrow
  color: white !important
