@use '@angular/material' as mat
@import 'general'
@import 'utils'
@import 'tour'
@import 'material-palette'

.app-wrapper
  display: flex
  flex-direction: row
  height: 100%

.content
  background: #fafafa
  height: calc(100% - 110px)
  margin-top: 110px
  overflow-y: hidden
  padding: 0
  position: relative
  width: 100%
  .ps
    height: 100%

// Main content
.section-layout
  position: relative
  padding: 0 24px
  padding-bottom: 24px
  width: 100%
  height: 100%
  margin: 0 auto
  text-decoration: none !important

  .form-card
    max-width: 1200px
    margin: 0 auto
    padding: 0 0 24px 0
    min-height: 400px

  .form-card-content
    margin-top: 120px
    padding: 24px

  .form-pad-container
    padding: 0 45px
    .subtitle
      margin-top: 24px
      margin-bottom: 24px
    @media screen and (max-width: 467px)
      padding: 0

  .form-card-field-container
    display: flex
    .form-card-field-full
      width: 100%
      padding: 0
      margin-bottom: 16px
      padding-right: 20px
    .form-card-fiel
      width: auto
      padding: 0 15px

    @media screen and (max-width: 800px)
      display: inline-block
      width: 100%

      .form-card-field
        padding: 0

      .form-pad-container
        padding: 0

  .title,
  .subtitle,
  .small-title
    font-family: 'Roboto', sans-serif
    font-style: normal
    font-stretch: normal
    line-height: normal
    letter-spacing: normal
    margin: 0
    color: $color-black !important
    text-decoration: none
  .title
    margin-right: 15px
    font-size: 22px
  .subtitle
    font-size: 20px
    font-weight: 500 !important

  .small-title
    text-decoration: none
    font-size: 16px
  p
    font-size: 15px
    line-height: 1.33
    text-align: left
    color: $color-black

  .caption,
  .caption-md,
  .cation-bold
    color: $color-black !important
    text-transform: uppercase

  .caption
    font-size: 11px
    text-align: left
  .caption-md
    font-size: 15px
    text-align: left
  .caption-bold
    font-size: 12px
    font-weight: 600

  textarea
    width: 100%
    min-height: 140px
    border-radius: 4px
    border: 1px solid $color-warm-grey
    padding: 10px

  hr
    border-top: 1px solid $color-divisions

  ::ng-deep p-calendar input
    height: 40px
    border-radius: 4px
    background-color: $color-white !important
    color: $color-pinkish-grey !important
    border: 1px solid $color-pinkish-grey !important
    padding: 6px 12px
    &:active,
    &:focus
      color: $color-warm-grey !important
      border: solid 1px $color-warm-grey !important
      outline: none
      box-shadow: none !important
    &[disabled]
      cursor: not-allowed
      filter: alpha(opacity=40)
      box-shadow: none
      opacity: .40
      &:focus,
      &:active
        background-color: $color-white !important
        color: $color-pinkish-grey !important
        border: 1px solid $color-pinkish-grey !important
        box-shadow: none !important
  .has-success
    input,
    textarea,
    select,
    label
      color: $color-apple-green !important
      border-color: $color-apple-green !important
  .has-error
    input,
    textarea,
    select,
    label
      color: $color-warning !important
      border-color: $color-warning !important

  a
    text-decoration: none !important
    cursor: pointer !important

  .custom-datepicker
    &.ui-calendar
      display: block !important
      width: 100%
      input
        width: 100%
      .ui-datepicker-header select
        color: $color-pinkish-grey !important
        border: 1px solid $color-pinkish-grey !important
    .ui-datepicker
      top: 40px !important

  .intl-tel-input
    width: 100%

.section-header
  height: auto
  display: flex
  justify-content: space-between
  align-items: center
  flex-flow: row wrap
  border-bottom: 1px solid $color-light-grey
  padding-bottom: 10px
  .pull-right
    margin-right: 0
    margin-right: 15px

.section-content
  position: relative
  margin-top: 25px
  padding: 0
  .form-group
    margin: auto 5px auto auto

.section-table-container
  display: inline-block
  .section-table
    table,
    tbody,
    .ui-widget-content
      border: 1px solid $color-light-grey !important
      color: $color-purplish-grey !important
    th
      color: $color-blue
    th.ui-sortable-column:hover
      background-color: $color-light-grey
      color: $color-blue
    th.ui-state-active
      background-color: $color-light-blue-grey
      color: $color-blue
    .table-field-overflow
      overflow: hidden
      display: inline-block
      text-overflow: ellipsis
      white-space: nowrap
      width: 150px

.section-search
  margin-bottom: 15px

.columns-container
  display: flex
  padding: 10px 0
  height: 100%
  flex: 1 1 auto

  .column-container
    width: 100%
    border: 1px solid $color-light-grey
    padding: 10px
    min-height: 100%
    margin-right: 15px
    box-shadow: $box-shadow
    transition: all 500ms ease
    &:last-child
      margin-right: 0
    .header-inline
      display: flex
      justify-content: space-between
      align-items: center
      flex-flow: row wrap

  @media screen and (max-width: 1105px)
    flex-wrap: wrap
    .column-container
      margin-right: 0
      margin-bottom: 15px

.loading
  position: fixed
  top: 0
  left: 0
  background-color: rgba(255, 255, 255, 0.9)
  background-image: url('../img/loading.gif')
  background-repeat: no-repeat
  background-position: center center
  width: 100%
  height: 100%
  opacity: 1
  transition: opacity .15s ease-in-out
  z-index: 99999

.caption1
  color: $color-purplish-grey
  font-size: em(11)
  text-transform: uppercase

.caption2
  color: $color-purplish-grey
  font-size: em(15)

.caption3
  color: $color-dark-navy
  font-size: em(12)
  text-transform: uppercase
  font-weight: 600

.btn,
.btn-primary
  font-size: em(14)
  font-weight: bold
  color: $color-white
  border: solid 1px $color-blue
  border-radius: 4px
  background: $color-blue
  text-transform: uppercase
  padding: 7px 45px

  &.btn-wide
    padding: 7px 118px

  &.btn-full-width
    width: 100%

  &.btn-narrow
    padding: 7px

  &:hover
    color: $color-blue
    background: $color-white

  &.btn-inverted
    color: $color-blue
    background: $color-white

    &:hover
      color: $color-white
      background: $color-blue

.btn-secondary
  background: $color-neon-blue
  border: solid 1px $color-neon-blue

  &:hover
    background: $color-white
    color: $color-neon-blue

  &.btn-inverted
    background: $color-white
    color: $color-neon-blue

    &:hover
      background: $color-neon-blue
      color: $color-white

.has-error
  input[type='text'],
  input[type='password']
    border: solid 1px $color-cherry-red !important
    color: $color-cherry-red !important

  .help-block
    color: $color-cherry-red !important
    font-size: em(13)

.alert
  padding: 16px 52px
  font-size: em(12)
  text-transform: uppercase
  border-radius: 0
  position: relative

  &::before
    content: ''
    width: 19px
    height: 19px
    position: absolute
    left: 20px
    top: 16px

  &.alert-danger
    color: $color-dark-navy
    border: solid 1px $color-cherry-red
    background: $color-white

    &::before
      content: ''
      background: url('../img/icon-error.png') center center no-repeat

  &.alert-success
    color: $color-dark-navy
    border: solid 1px $color-apple-green
    background: $color-white

    &::before
      content: ''
      background: url('../img/icon-success.png') center center no-repeat

  &.alert-info
    color: $color-dark-navy
    border: solid 1px $color-blue
    background: $color-white

    &::before
      content: ''
      background: url('../img/icon-info.png') center center no-repeat

  &.alert-warning
    color: $color-dark-navy
    border: solid 1px $color-tangerine
    background: $color-white

    &::before
      content: ''
      background: url('../img/icon-warning.png') center center no-repeat

.content-box
  background-color: $color-white
  box-shadow: $box-shadow
  border: solid 1px $color-light-blue-grey
  z-index: 1

select
  border-radius: 4px
  background: $color-white
  border: solid 1px $color-warm-grey !important
  color: $color-purplish-grey
  font-size: em(13)
  padding: 9px 12px
  min-height: 40px
  box-shadow: none
  outline: none
  box-shadow: none !important
  width: 100%
  height: 34px

.loginscreen
  .header
    .title h1
      margin: 0 auto

  &.content
    width: 100% !important
    margin-left: 0 !important

.vehicle-pic-placeholder
  background: $color-light-blue-grey url('../img/vehicle-pic-placeholder.png') center center no-repeat
  min-height: 300px
  padding: 0
  border-radius: 4px
  overflow: hidden

  img
    width: 100%
    height: auto

.license-pic-placeholder
  background: $color-light-blue-grey url('../img/license-placeholder.png') center center no-repeat
  background-size: 20%
  min-height: 300px
  padding: 0
  border-radius: 4px
  overflow: hidden

  img
    width: 100%
    height: auto

.uploadVehicle
  background: $color-light-blue-grey url('../img/vehicle-pic-placeholder.png') center 18px no-repeat
  background-size: 45%
  width: 150px
  height: 120px
  margin: 0 auto
  border-radius: 4px
  text-align: center
  position: relative
  overflow: hidden

  input[type=file]
    width: 0.1px
    height: 0.1px
    opacity: 0
    overflow: hidden
    position: absolute
    z-index: -1

    & + label
      position: absolute
      bottom: 0
      left: 0
      width: 100%

  & img
    width: 100%
    margin: 0 auto

.vehicleInfo
  margin-right: 15px

.uploadLicense
  background: $color-light-blue-grey url('../img/license-placeholder.png') center 18px no-repeat
  background-size: 32%
  width: 150px
  height: 120px
  border-radius: 4px
  text-align: center
  position: relative
  overflow: hidden

  input[type=file]
    width: 0.1px
    height: 0.1px
    opacity: 0
    overflow: hidden
    position: absolute
    z-index: -1

    & + label
      position: absolute
      bottom: 0
      left: 0
      width: 100%

  & img
    width: 100%
    margin: 0 auto

  .inputfile-4:focus + label figure,
  .inputfile-4.has-focus + label figure,
  .inputfile-4 + label:hover figure
    background-color: #722040

.upload-picture-driver
  @extend .uploadLicense

.vehicleInfo
  margin-right: 15px

a.listing-link
  color: $color-blue
  text-decoration: none
  cursor: pointer

  &:hover
    color: $color-neon-blue
    text-decoration: underline

.listing-actions
  text-align: center

  a
    margin: 0 10px
    cursor: pointer

.vehicle-info
  width: calc(50% - 15px)
  margin-left: 15px

  .title-vehicle-assigned
    position: relative

    .view-history
      position: absolute
      right: 15px
      top: 1.2em

  .title-costs
    h2
      margin-top: 0

    .view-history
      position: absolute
      right: 15px
      top: 0

  .cost-item
    display: table
    width: 100%
    border-bottom: 1px solid #eee
    margin: 10px auto
    padding: 10px 0

    &.cost-total
      border-bottom: 0

    .cost-cell
      display: table-cell
      text-align: center

    .cost-total
      text-align: right
      font-weight: bold

  .route-map-placeholder
    width: 100%

    img
      width: 100%

.driver-info-container
  min-width: 351px
  max-width: 351px
  min-height: 100px
  margin-top: 70px !important
  margin-left: -194px !important
  position: relative
  box-shadow: $box-shadow
  &:after
    content: ''
    position: absolute
    right: -6px
    top: 94px
    width: 0
    height: 0
    border-top: 10px solid transparent
    border-bottom: 10px solid transparent
    border-left: 10px solid $color-white
  .si-shadow-wrapper-top,
  .si-pointer-top.si-pointer-border-top
    display: none
  .si-content-wrapper
    padding: 0 !important
    border: 0 !important
  .si-content
    max-width: initial !important
    max-height: initial !important

@keyframes rotation
  from
    transform: rotate(0deg)
  to
    transform: rotate(359deg)

// will display autocomplete on modals
.pac-container
  z-index: 1051 !important
  background-color: $color-white
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.14), 0 4px 15px 0 rgba(0, 0, 0, 0.2)
  &:after
    display: none
  .pac-item
    min-height: 50px
    padding: 0 20px
    color: $color-dark-grey
    background-color: $color-white
    font-size: 14px
    font-weight: normal
    border: 0
    display: flex
    align-items: center
    flex-wrap: wrap
    .pac-icon
      display: none
    span
      width: 100%
    &:hover
      background-color: $color-white-four

.capitalize-text
  text-transform: capitalize !important

.uppercase-text
  text-transform: uppercase !important

.lowercase-text
  text-transform: lowercase

.ps__rail-y
  .ps__thumb-y
    min-height: 32px !important

.pointer
  cursor: pointer

.disabled-item,
.disabled-link
  pointer-events: none
  opacity: 0.6
  cursor: not-allowed !important

button
  &.mat-mdc-raised-button
    &.mat-primary
      color: $color-white !important
      background-color: $color-turquoise-blue
    &.mat-secondary
      color: $color-white !important
      background-color: $color-blue-grey
  &.mat-button
    &.mat-primary
      color: $color-turquoise-blue !important
    &.mat-secondary
      color: $color-blue-grey !important

.dissapear
  display: none !important

// global bootstrap modal
.modal-dialog
  .modal-content
    border-radius: 2px !important
    min-height: 250px
    .modal-title,
    .modal-subtitle
      font-family: 'Roboto', sans-serif
      font-style: normal
      font-stretch: normal
      line-height: normal
      letter-spacing: normal
      margin: 0
      color: $color-dark-grey !important
      text-decoration: none
      font-size: 20px
      font-weight: 500
    .modal-subtitle
      margin-top: 5px
      font-size: 16px
      font-weight: normal

    .modal-header
      border: 0 !important

    .modal-body
      background-color: $color-white-three

    .modal-footer
      border-top: 0

    .form-card-field-full
      width: 100%

.no-content-placeholder
  display: flex
  width: 100%
  margin: auto
  align-items: center
  justify-content: center
  flex-wrap: wrap
  .holder
    display: flex
    justify-content: center
    width: 100%
  .title
    font-size: 16px
    font-weight: 500
    color: $color-dark-grey
    margin: 0
    .anchor
      color: $color-turquoise-blue
  .action-button
    width: 80px
    height: 80px
    margin-top: 5px
    color: $color-light-grey
    font-size: 80px
    .icon-size
      font-size: 80px
      width: 100%
    span
      display: flex
      margin: 0 auto

.inverted
  transform: scaleX(-1)

#custom-box-shadow
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2)
  width: 100%
  height: 1px
  position: absolute
  border: 1px solid $color-azure
  top: 108px
  z-index: 100

@keyframes pulse
  to
    box-shadow: 0 0 0 22px rgba(0, 153, 219, 0)
