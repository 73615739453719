// Margins
.m-n
  margin: 0 !important

.m-t-n
  margin-top: 0 !important

.m-r-n
  margin-right: 0 !important

.m-b-n
  margin-bottom: 0 !important

.m-l-n
  margin-left: 0 !important

.m-t
  margin-top: $size-base

.m-r
  margin-right: $size-base

.m-b
  margin-bottom: $size-base

.m-l
  margin-left: $size-base

.m-xs
  margin: $size-xs !important

.m-t-xs
  margin-top: $size-xs !important

.m-r-xs
  margin-right: $size-xs !important

.m-b-xs
  margin-bottom: $size-xs !important

.m-l-xs
  margin-left: $size-xs !important

.m-sm
  margin: $size-sm

.m-t-sm
  margin-top: $size-sm

.m-r-sm
  margin-right: $size-sm

.m-b-sm
  margin-bottom: $size-sm

.m-l-sm
  margin-left: $size-sm

.m-md
  margin: $size-md !important

.m-t-md
  margin-top: $size-md !important

.m-r-md
  margin-right: $size-md !important

.m-b-md
  margin-bottom: $size-md !important

.m-l-md
  margin-left: $size-md !important

.m-xmd
  margin: $size-xmd !important

.m-t-xmd
  margin-top: $size-xmd !important

.m-r-xmd
  margin-right: $size-xmd !important

.m-b-xmd
  margin-bottom: $size-xmd !important

.m-l-xmd
  margin-left: $size-xmd !important

.m-xxm
  margin: $size-xxm

.m-t-xxm
  margin-top: $size-xxm

.m-r-xxm
  margin-right: $size-xxm

.m-b-xxm
  margin-bottom: $size-xxm

.m-l-xxm
  margin-left: $size-xxm

.m-xlg
  margin: $size-xlg

.m-t-xlg
  margin-top: $size-xlg

.m-r-xlg
  margin-right: $size-xlg

.m-b-xlg
  margin-bottom: $size-xlg

.m-l-xlg
  margin-left: $size-xlg

.m-lg
  margin: $size-lg

.m-t-lg
  margin-top: $size-lg

.m-r-lg
  margin-right: $size-lg

.m-b-lg
  margin-bottom: $size-lg

.m-l-lg
  margin-left: $size-lg

.m-xl
  margin: $size-xl

.m-t-xl
  margin-top: $size-xl

.m-r-xl
  margin-right: $size-xl

.m-b-xl
  margin-bottom: $size-xl

.m-l-xl
  margin-left: $size-xl

.m-xxl
  margin: $size-xxl

.m-t-xxl
  margin-top: $size-xxl

.m-r-xxl
  margin-right: $size-xxl

.m-b-xxl
  margin-bottom: $size-xxl

.m-l-xxl
  margin-left: $size-xxl

// Padding
.p-n
  padding: 0 !important

.p-l-n
  padding-left: 0 !important

.p-r-n
  padding-right: 0 !important

.p-t-n
  padding-top: 0 !important

.p-b-n
  padding-bottom: 0 !important

.p-xs
  padding: $size-xs !important

.p-t-xs
  padding-top: $size-xs !important

.p-r-xs
  padding-right: $size-xs !important

.p-b-xs
  padding-bottom: $size-xs !important

.p-l-xs
  padding-left: $size-xs !important

.p-sm
  padding: $size-sm !important

.p-t-sm
  padding-top: $size-sm !important

.p-r-sm
  padding-right: $size-sm !important

.p-b-sm
  padding-bottom: $size-sm !important

.p-l-sm
  padding-left: $size-sm !important

.p-md
  padding: $size-md !important

.p-t-md
  padding-top: $size-md !important

.p-r-md
  padding-right: $size-md !important

.p-b-md
  padding-bottom: $size-md !important

.p-l-md
  padding-left: $size-md !important

.p-xmd
  padding: $size-xmd !important

.p-t-xmd
  padding-top: $size-xmd !important

.p-r-xmd
  padding-right: $size-xmd !important

.p-b-xmd
  padding-bottom: $size-xmd !important

.p-l-xmd
  padding-left: $size-xmd !important

.p-lg
  padding: $size-lg

.p-t-lg
  padding-top: $size-lg

.p-r-lg
  padding-right: $size-lg

.p-b-lg
  padding-bottom: $size-lg !important

.p-l-lg
  padding-left: $size-lg

.padder-sm
  padding-left: $size-sm !important
  padding-right: $size-sm !important

.padder-md
  padding-left: $size-md !important
  padding-right: $size-md !important

.padder-lg
  padding-left: $size-lg !important
  padding-right: $size-lg !important

// Width
.w-1
  width: 5%

.w-2
  width: 10%

.w-3
  width: 15%

.w-4
  width: 20%

.w-5
  width: 25%

.w-6
  width: 30%

.w-7
  width: 35%

.w-8
  width: 40%

.w-9
  width: 45%

.w-10
  width: 50%

.w-11
  width: 55%

.w-12
  width: 60%

.w-13
  width: 65%

.w-14
  width: 70%

.w-15
  width: 75%

.w-16
  width: 80%

.w-17
  width: 85%

.w-18
  width: 90%

.w-19
  width: 95%

.w-20
  width: 100%

.w-300
  width: 300px

// Flex
.flex-container
  display: flex

.flex-container.row
  flex-flow: row nowrap

.flex-container.column
  flex-flow: column nowrap

.flex-center
  align-items: center

.full-height
  height: 100% !important

.w-full
  width: 100% !important

// Different standard dimensions
.space-xs
  width: 1px !important
  height: 1px  !important

.capitalize
  text-transform: capitalize

@media (min-width: $screen-xlg)
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12
    float: left
    position: relative
    min-height: 1px
    padding-right: 15px
    padding-left: 15px

  .col-xl-12
    width: 100%

  .col-xl-11
    width: 91.66666667%

  .col-xl-10
    width: 83.33333333%

  .col-xl-9-5
    width: 80.333333%

  .col-xl-9
    width: 75%

  .col-xl-8
    width: 66.66666667%

  .col-xl-7
    width: 58.33333333%

  .col-xl-6
    width: 50%

  .col-xl-5
    width: 41.66666667%

  .col-xl-4
    width: 33.33333333%

  .col-xl-3
    width: 25%

  .col-xl-2-5
    width: 19.666667%

  .col-xl-2
    width: 16.66666667%

  .col-xl-1
    width: 8.33333333%

.divider
  height: 3px
  overflow: hidden
  background-color: #d0cccc